// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, List, ListItemButton, ListItemIcon, ListItemText, Typography, Grid, Link } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import MegaMenuSection from './MegaMenuSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import useConfig from 'hooks/useConfig';

import { IconHelp, IconNews, IconBuildingStore } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            '&:hover': {
              background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
              color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
            }
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="20px" />
        </Avatar>
      </Box>

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Box component="span" sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid item xs={4}>
          <List
            component="nav"
            aria-labelledby="nested-list-user"
            className="topMenu"
          >
            <ListItemButton disableRipple component="a"
              sx={{ borderRadius: `${borderRadius}px`, alignItems: 'flex-start' }}
              href="https://app.getbeamer.com/lollyadvance/"
              target="_blank"
            >
              <ListItemIcon sx={{ my: 'auto', minWidth: 36, display: 'inline-flex', verticalAlign: 'middle' }}>
                <IconNews sx={{
                  '&:hover': {
                    color: '#5E35B1'
                  }
                }} />
              </ListItemIcon>
              <ListItemText sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
                primary={<Typography variant="body2" sx={{ '&:hover': { color: '#5E35B1' } }}>
                  What's new</Typography>} />
            </ListItemButton>
            <ListItemButton disableRipple component="a"
              sx={{ borderRadius: `${borderRadius}px`, alignItems: 'flex-start' }}
              href="/terms"
            >
              <ListItemIcon sx={{ my: 'auto', minWidth: 36, display: 'inline-flex', verticalAlign: 'middle' }}>
                <IconNews sx={{
                  '&:hover': {
                    color: '#5E35B1'
                  }
                }} />
              </ListItemIcon>
              <ListItemText sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
                primary={<Typography variant="body2" sx={{ '&:hover': { color: '#5E35B1' } }}>
                  Terms of use</Typography>} />
            </ListItemButton>
            {/* <ListItemButton disableRipple component="a"
                        sx={{ borderRadius: `${borderRadius}px` }}
                        href="https://lollyadvance.com/product-category/lolly-advance/"
                        target="_blank"
                    >
                        <ListItemIcon sx={{ my: 'auto', minWidth: 36, display: 'inline-flex', verticalAlign: 'middle' }}>
                            <IconBuildingStore sx={{ 
                                '&:hover': {
                                    color: '#5E35B1'
                                }
                            }}/>
                        </ListItemIcon>
                        <ListItemText sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
                            primary={<Typography variant="body2" sx={{ '&:hover': { color: '#5E35B1' }}}>
                        Get Advance</Typography>} />
                    </ListItemButton>
                    <ListItemButton disableRipple component="a"
                        sx={{ borderRadius: `${borderRadius}px` }}
                        href="https://lollyadvance.com/product-category/lolly-property/"
                        target="_blank"
                    >
                        <ListItemIcon sx={{ my: 'auto', minWidth: 36, display: 'inline-flex', verticalAlign: 'middle' }}>
                            <IconBuildingStore sx={{
                                    '&:hover': {
                                        color: '#5E35B1'
                                    }
                                }}/>
                        </ListItemIcon>
                        <ListItemText sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
                            primary={<Typography variant="body2" sx={{ '&:hover': { color: '#5E35B1' }}}>
                            Get Property</Typography>} />
                    </ListItemButton>
                    <ListItemButton disableRipple component="a"
                        sx={{ borderRadius: `${borderRadius}px` }}
                        href="https://lollyadvance.com/product-category/lolly-vip/"
                        target="_blank"
                    >
                        <ListItemIcon sx={{ my: 'auto', minWidth: 36, display: 'inline-flex', verticalAlign: 'middle' }}>
                            <IconBuildingStore sx={{
                                    '&:hover': {
                                        color: '#5E35B1'
                                    }
                                }}/>
                        </ListItemIcon>
                        <ListItemText sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
                            primary={<Typography variant="body2" sx={{ '&:hover': { color: '#5E35B1' }}}>
                            Get VIP</Typography>} />
                    </ListItemButton> */}
          </List>
        </Grid>
      </Box>
      {/* notification & profile */}
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
