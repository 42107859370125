import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import GuestRoutes from './GuestRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NewRoutes from './NewRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <Navigate to="/newsfeed" />
        </AuthGuard>
      )
    },
    AuthenticationRoutes,
    LoginRoutes,
    MainRoutes,
    NewRoutes,
    GuestRoutes
  ]);
}
