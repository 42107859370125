// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconUserCircle, IconBellRinging, IconSettings, IconUserCheck, IconAlphabetGreek } from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconUserCircle,
  IconBellRinging,
  IconSettings,
  IconUserCheck,
  IconAlphabetGreek
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const business = {
  id: 'business',
  type: 'group',
  title: <FormattedMessage id="My businesses" />,
  children: [
    {
      id: 'my-business',
      title: <FormattedMessage id="Businesses and Markets" />,
      type: 'collapse',
      icon: icons.IconAlphabetGreek,
      breadcrumbs: true,
      children: [
        {
          id: 'add-business',
          title: <FormattedMessage id="Add business" />,
          type: 'item',
          url: '/business/add-business',
          breadcrumbs: true,
          // disabled: true
        },
        {
          id: 'invite-business-admin',
          title: <FormattedMessage id="Invite admins" />,
          type: 'item',
          url: '/business/invite-business-admin',
          breadcrumbs: true,
          disabled: true
        }
      ]
    },
  ]
};

export default business;
