import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  selectedItem: ['newsfeed'],
  drawerOpen: false,
  drawerOpenRight: false
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.selectedItem = action.payload;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },
    openDrawerRight: (state, action) => {
      state.drawerOpenRight = action.payload;
    }
  }
});

export default menu.reducer;

export const { activeItem, openDrawer, openDrawerRight } = menu.actions;
