// project imports
import services from 'utils/mockAdapter';

// assets
import image1 from 'assets/images/profile/img-profile1.png';
import image2 from 'assets/images/profile/img-profile2.jpg';
import image4 from 'assets/images/profile/img-profile4.jpg';

// social profile
let posts = [
    {
        id: '#4POST_JONE_DOE',
        profile: {
            id: '#52JONE_DOE',
            avatar: 'lolly-icon.png',
            name: 'Apps and integration',
            time: 'now'
        },
        data: {
            id: 'newsfeed1',
            title: 'Apps and integration',
            content: `Apps and integration
            \n  Not available yet`,
            images: [],
            likes: {
                like: false,
                value: 0
            },
            comments: []
        }
    }
];

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/posts/list').reply(200, { posts });
