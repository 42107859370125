// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  IconBrandChrome, IconHelp, IconSitemap, IconUsers, IconNews, IconCoin, IconCurrencyDollar, IconWallet,
  IconSettings, IconBuildingBank, IconFiles, IconHomeDollar, IconVip, IconSpeakerphone, IconAward, IconAlphabetGreek,
  IconHierarchy,
  IconNumbers
} from '@tabler/icons';

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconUsers,
  IconNews,
  IconCoin,
  IconCurrencyDollar,
  IconWallet,
  IconSettings,
  IconBuildingBank,
  IconFiles,
  IconHomeDollar,
  IconVip,
  IconSpeakerphone,
  IconAward,
  IconAlphabetGreek,
  IconHierarchy,
  IconNumbers
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'admin',
  title: <FormattedMessage id="God Admin" />,
  type: 'group',
  admin: true,
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="Users" />,
      type: 'collapse',
      icon: icons.IconUsers,
      breadcrumbs: true,
      children: [
        {
          id: 'users',
          title: <FormattedMessage id="View all User IDs" />,
          type: 'item',
          url: '/admin/users',
          // disabled: true,
          admin: true
        },
        {
          id: 'invite-user',
          title: <FormattedMessage id="Invite User" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
      ]
    },
    {
      id: 'admin-newsfeed',
      title: <FormattedMessage id="Newsfeed" />,
      type: 'collapse',
      icon: icons.IconNews,
      breadcrumbs: true,
      children: [
        {
          id: 'admin-view-newsfeed',
          title: <FormattedMessage id="View all News" />,
          type: 'item',
          url: '/admin/newsfeed',
          // disabled: true,
          admin: true
        },
        {
          id: 'create-news',
          title: <FormattedMessage id="Create News" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
        {
          id: 'send-news',
          title: <FormattedMessage id="Send News" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
      ]
    },
    {
      id: 'advantages',
      title: <FormattedMessage id="Advantages" />,
      type: 'collapse',
      icon: icons.IconCoin,
      breadcrumbs: true,
      children: [
        {
          id: 'view-advantages',
          title: <FormattedMessage id="View all advantages IDs" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
        {
          id: 'create-advantages',
          title: <FormattedMessage id="Create advantages" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
        {
          id: 'send-advantages',
          title: <FormattedMessage id="Send advantages" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
      ]
    },
    {
      id: 'brands',
      title: <FormattedMessage id="Brands" />,
      type: 'collapse',
      icon: icons.IconAward,
      breadcrumbs: true,
      children: []
    },
    {
      id: 'businesses',
      title: <FormattedMessage id="Businesses" />,
      type: 'collapse',
      icon: icons.IconAlphabetGreek,
      breadcrumbs: true,
      children: []
    },
    {
      id: 'admin-key-accounts',
      title: <FormattedMessage id="Key accounts" />,
      type: 'collapse',
      icon: icons.IconHierarchy,
      breadcrumbs: true,
      children: []
    },
    {
      id: 'brand-services',
      title: <FormattedMessage id="Brand services" />,
      type: 'collapse',
      icon: icons.IconSpeakerphone,
      breadcrumbs: true,
      children: [
        {
          id: 'create-campaign',
          title: <FormattedMessage id="Brand templates" />,
          type: 'item',
          url: '/admin/brands/create',
          // disabled: true,
          admin: true
        },
        {
          id: 'create-service',
          title: <FormattedMessage id="Create Brand Services" />,
          type: 'item',
          url: '/admin/create-service',
          // disabled: true,
          admin: true
        },
        {
          id: 'delivered-services',
          title: <FormattedMessage id="Delivered Services" />,
          type: 'item',
          url: '/admin/delivered-service',
          // disabled: true,
          admin: true
        },
        {
          id: 'services-overview',
          title: <FormattedMessage id="All Brand Services" />,
          type: 'item',
          url: '/admin/services-overview',
          // disabled: true,
          admin: true
        },
      ]
    },
    {
      id: 'business-services',
      title: <FormattedMessage id="Business services" />,
      type: 'collapse',
      icon: icons.IconAlphabetGreek,
      breadcrumbs: true,
      children: [
        {
          id: 'create-campaign',
          title: <FormattedMessage id="Templates" />,
          type: 'item',
          // url: '/admin/business/create',
          disabled: true,
          admin: true
        },
      ]
    },
    {
      id: 'la-admin',
      title: <FormattedMessage id="Advance" />,
      type: 'collapse',
      icon: icons.IconWallet,
      breadcrumbs: true,
      children: [
        {
          id: 'la',
          title: <FormattedMessage id="View all Advance IDs" />,
          type: 'item',
          url: '/admin/advance',
          admin: true
        },
        {
          id: 'admin-la-transactions',
          title: <FormattedMessage id="Transactions" />,
          type: 'item',
          url: '/admin/advance/transactions',
          breadcrumbs: true,
          admin: true
        },
        {
          id: 'send-advance',
          title: <FormattedMessage id="Send Advance" />,
          type: 'item',
          url: '/admin/advance/send',
          admin: true
        },
        {
          id: 'send-advance-to',
          title: <FormattedMessage id="Send Advance To Property/VIP" />,
          type: 'item',
          url: '/admin/advance/send-to',
          admin: true
        },
        {
          id: 'create-la',
          title: <FormattedMessage id="Create Advance" />,
          type: 'item',
          url: '/admin/advance/create',
          admin: true
        },
        {
          id: 'all-services',
          title: <FormattedMessage id="View all Advance services" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
        {
          id: 'create-advance-service',
          title: <FormattedMessage id="Create Advance service" />,
          type: 'item',
          url: '/admin/advance/create-service',
          admin: true
        },
      ]
    },
    {
      id: 'property',
      title: <FormattedMessage id="Property" />,
      type: 'collapse',
      icon: icons.IconHomeDollar,
      breadcrumbs: true,
      children: [
        {
          id: 'lolly-property',
          title: <FormattedMessage id="View all Property IDs" />,
          type: 'item',
          url: '/admin/property',
          admin: true
          // disabled: true
        },
        {
          id: 'send-property',
          title: <FormattedMessage id="Send Property" />,
          type: 'item',
          url: '#',
          url: '/admin/property/send',
          // disabled: true,
          admin: true
        },
        {
          id: 'create-property',
          title: <FormattedMessage id="Create Property" />,
          type: 'item',
          url: '/admin/property/create',
          admin: true
          // disabled: true
        },
      ]
    },
    {
      id: 'money',
      title: <FormattedMessage id="Money" />,
      type: 'collapse',
      icon: icons.IconCurrencyDollar,
      breadcrumbs: true,
      children: [
        {
          id: 'money-all',
          title: <FormattedMessage id="View all Money IDs" />,
          type: 'item',
          url: '/admin/money/all',
          breadcrumbs: true,
          admin: true
        },
        {
          id: 'admin-usd-transactions',
          title: <FormattedMessage id="Transactions" />,
          type: 'item',
          url: '/admin/money/transactions',
          // disabled: true,
          admin: true
        },
        {
          id: 'send-money',
          title: <FormattedMessage id="Send money" />,
          type: 'item',
          url: '/admin/money/send',
          // disabled: true,
          admin: true
        },

      ]
    },
    {
      id: 'vip',
      title: <FormattedMessage id="VIP" />,
      type: 'collapse',
      icon: icons.IconVip,
      breadcrumbs: true,
      children: [
        {
          id: 'lolly-vip',
          title: <FormattedMessage id="View all VIP IDs" />,
          type: 'item',
          url: '/admin/vip',
          // disabled: true,
          admin: true
        },
        {
          id: 'admin-vip-transactions',
          title: <FormattedMessage id="Transactions" />,
          type: 'item',
          url: '/admin/vip/transactions',
          // disabled: true,
          admin: true
        },
        {
          id: 'send-vip',
          title: <FormattedMessage id="Send VIP" />,
          type: 'item',
          url: '/admin/vip/send',
          // disabled: true,
          admin: true
        },
        {
          id: 'create-vip',
          title: <FormattedMessage id="Create  VIP" />,
          type: 'item',
          url: '/admin/vip/create',
          // disabled: true,
          admin: true
        },
      ]
    },
    {
      id: 'issuers',
      title: <FormattedMessage id="Issuers" />,
      type: 'collapse',
      icon: icons.IconBuildingBank,
      breadcrumbs: true,
      children: [
        {
          id: 'all-issuers',
          title: <FormattedMessage id="View all issuers" />,
          type: 'item',
          url: '/admin/ids/issuer',
          // disabled: true,
          admin: true
        },
        {
          id: 'create-issuer',
          title: <FormattedMessage id="Create issuer" />,
          type: 'item',
          url: '/admin/create-issuer',
          admin: true
        }
      ]
    },
    {
      id: 'admin-billing',
      title: <FormattedMessage id="Admin & Billing" />,
      type: 'collapse',
      icon: icons.IconFiles,
      breadcrumbs: true,
      children: [
        {
          id: 'send-document',
          title: <FormattedMessage id="Send document" />,
          type: 'item',
          url: '#',
          disabled: true,
          admin: true
        },
        {
          id: 'admins-ids',
          title: <FormattedMessage id="View all admins IDs" />,
          type: 'item',
          url: '/admin/ids/admins',
          admin: true
        },
        {
          id: 'billing-ids',
          title: <FormattedMessage id="View all billing IDs" />,
          type: 'item',
          url: '/admin/ids/billing',
          admin: true
        },
        {
          id: 'documents-ids',
          title: <FormattedMessage id="View all documents IDs" />,
          type: 'item',
          url: '/admin/ids/documents',
          admin: true
        },
      ]
    },
    {
      id: 'agents',
      title: <FormattedMessage id="Agents" />,
      type: 'collapse',
      icon: icons.IconFiles,
      breadcrumbs: true,
      children: [
        {
          id: 'agent-ids',
          title: <FormattedMessage id="View all Agent IDs" />,
          type: 'item',
          url: '/admin/agents/ids',
          admin: true
        },
      ]
    },
    {
      id: 'ids',
      title: <FormattedMessage id="IDs" />,
      type: 'collapse',
      icon: icons.IconNumbers,
      breadcrumbs: true,
      children: [
        {
          id: 'la-ids',
          title: <FormattedMessage id="View all Advance IDs" />,
          type: 'item',
          url: '/admin/ids/advance',
          admin: true
        },
        {
          id: 'lp-ids',
          title: <FormattedMessage id="View all Property IDs" />,
          type: 'item',
          url: '/admin/ids/property',
          admin: true
          // disabled: true
        },
        {
          id: 'money-ids',
          title: <FormattedMessage id="View all Money IDs" />,
          type: 'item',
          url: '/admin/ids/money',
          breadcrumbs: true,
          admin: true
        },
        {
          id: 'vip-ids',
          title: <FormattedMessage id="View all VIP IDs" />,
          type: 'item',
          url: '/admin/ids/vip',
          // disabled: true,
          admin: true
        },
        {
          id: 'users-ids',
          title: <FormattedMessage id="View all User IDs" />,
          type: 'item',
          url: '/admin/ids/users',
          // disabled: true,
          admin: true
        },
        {
          id: 'advantages-ids',
          title: <FormattedMessage id="View all advantages IDs" />,
          type: 'item',
          url: '/admin/ids/advantages',
          admin: true
        },
        {
          id: 'issuer-ids',
          title: <FormattedMessage id="View all issuer IDs" />,
          type: 'item',
          url: '/admin/ids/issuer',
          admin: true
        },
        {
          id: 'brands-ids',
          title: <FormattedMessage id="View all brands IDs" />,
          type: 'item',
          url: '/admin/ids/brands',
          admin: true
        },
        {
          id: 'businesses-ids',
          title: <FormattedMessage id="View all businesses IDs" />,
          type: 'item',
          url: '/admin/ids/businesses',
          admin: true
        },
        {
          id: 'key-accounts-ids',
          title: <FormattedMessage id="View all key accounts IDs" />,
          type: 'item',
          url: '/admin/ids/key-accounts',
          admin: true
        },
        {
          id: 'brand-services-ids',
          title: <FormattedMessage id="View all brand services IDs" />,
          type: 'item',
          url: '/admin/ids/brand-services',
          admin: true
        },
        {
          id: 'business-services-ids',
          title: <FormattedMessage id="View all business services IDs" />,
          type: 'item',
          url: '/admin/ids/business-services',
          admin: true
        },
        {
          id: 'admins-ids',
          title: <FormattedMessage id="View all admins IDs" />,
          type: 'item',
          url: '/admin/ids/admins',
          admin: true
        },
        {
          id: 'billing-ids',
          title: <FormattedMessage id="View all billing IDs" />,
          type: 'item',
          url: '/admin/ids/billing',
          admin: true
        },
        {
          id: 'documents-ids',
          title: <FormattedMessage id="View all documents IDs" />,
          type: 'item',
          url: '/admin/ids/documents',
          admin: true
        },
        {
          id: 'chipbot-ids',
          title: <FormattedMessage id="View all chipbot video IDs" />,
          type: 'item',
          url: '/admin/ids/chipbot',
          admin: true
        },
      ]
    },
    {
      id: 'future-features',
      title: <FormattedMessage id="Future features" />,
      type: 'collapse',
      // icon: icons.IconFiles,
      breadcrumbs: true,
      children: [
        {
          id: 'features',
          title: <FormattedMessage id="Features" />,
          type: 'item',
          url: '/features',
          // disabled: true,
        },
        {
          id: 'agency',
          title: <FormattedMessage id="Agency commission" />,
          type: 'item',
          url: '/agency',
          // disabled: true,
        },
      ]
    },
    // {
    //     id: 'admin-send',
    //     title: <FormattedMessage id="Admin Send" />,
    //     type: 'item',
    //     url: '/admin/send',
    //     admin: true
    // },
  ]
};

export default other;
