import dashboard from './dashboard';
import brands from './brands';
import admin from './admin';
import business from './business';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, business, admin]
};

export default menuItems;
