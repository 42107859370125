import { useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import useNavigationEvent from 'hooks/useNavigationEvent';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  const location = useLocation();
  const { trackPage, triggerNavigationEvent } = useNavigationEvent();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { container } = useConfig();
  const { onChangeMenuType } = useConfig();

  const matched = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isDarkMode = () => {
    if (matched === true) {
      onChangeMenuType('dark');
    } else {
      onChangeMenuType('light');
    }
  };

  const checkPathInNavigation = (navObject, currentPath) => {
    // console.log(currentPath)
    for (let item of navObject.items) {
      if (item.type === 'item' && item.url === currentPath) {
        return true;
      }

      if (item.type === 'group' || item.type === 'collapse') {
        const inChildren = checkPathInNavigation({ items: item.children }, currentPath);
        if (inChildren) {
          return true;
        }
      }
    }
    return false;
  };

  // checks if the url is in navigation
  // if the menu items are in navigation, we can show the breadcrumbs that come from the menu
  // otherwise we need to add breadcrumbs manually
  const [showNavBreadcrumbs, setShowNavBreadcrumbs] = useState(false);


  useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isDarkMode();
    const isPathInNavigation = checkPathInNavigation(navigation, location.pathname);
    setShowNavBreadcrumbs(isPathInNavigation);
    // setShowNavBreadcrumbs(true);
  }, [matchDownMd, location.pathname]);

  const header = useMemo(
    () => (
      <Toolbar>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none'
        }}
      >
        {header}
      </AppBar>

      {/* drawer */}
      <Sidebar />

      {/* main content */}
      <Main theme={theme} open={drawerOpen}>
        {/* breadcrumb */}
        {showNavBreadcrumbs && container && (
          <Container maxWidth="lg">
            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
            <Outlet />
          </Container>
        )}
        {showNavBreadcrumbs && !container && (
          <>
            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
            <Outlet />
          </>
        )}
        {/* Breadcrumbs added from the template for now */}
        {!showNavBreadcrumbs && container && (
          <Container maxWidth="lg">
            <Outlet />
          </Container>
        )}
        {!showNavBreadcrumbs && !container && (
          <>
            <Outlet />
          </>
        )}
      </Main>
    </Box>
  );
};

export default MainLayout;
