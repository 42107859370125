import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AdminGuard from 'utils/route-guard/AdminGuard';
import { RE } from 'country-flag-icons/react/3x2';

// dashboard routing
const Verification = Loadable(lazy(() => import('views/lolly/ValidationWizard/Verification')));
const NewsFeed = Loadable(lazy(() => import('views/lolly/NewsFeed')));
const NewsFeedTest = Loadable(lazy(() => import('views/lolly/NewsFeedTest')));
const AdminNewsFeed = Loadable(lazy(() => import('views/admin/AdminNewsFeed')));
const Terms = Loadable(lazy(() => import('views/lolly/Terms')));
const DataInsights = Loadable(lazy(() => import('views/lolly/DataInsights')));
const AuditTrail = Loadable(lazy(() => import('views/brand/AuditTrail')));
const ActivityLog = Loadable(lazy(() => import('views/lolly/ActivityLog')));
const BrandNewsfeed = Loadable(lazy(() => import('views/brand/newsfeed')));
const Documents = Loadable(lazy(() => import('views/lolly/Documents')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Suggestions = Loadable(lazy(() => import('views/brand/suggestions')));
const Calendar = Loadable(lazy(() => import('views/brand/calendar')));
const Billing = Loadable(lazy(() => import('views/lolly/brand/Billing')));
const Admins = Loadable(lazy(() => import('views/lolly/brand/Admins')));

const CreateCampaign = Loadable(lazy(() => import('views/brand/calendar/index')));
const Campaigns = Loadable(lazy(() => import('views/brand/calendar/campaigns')));
const Proposal = Loadable(lazy(() => import('views/brand/calendar/Proposal')));
const Overview = Loadable(lazy(() => import('views/brand/overview')));
const CampaignOrderConfirmation = Loadable(lazy(() => import('views/brand/campaignOrderConfirmation')));
const ThankYouPage = Loadable(lazy(() => import('views/brand/thankYouPage')));
const AddCompetitor = Loadable(lazy(() => import('views/brand/AddCompetitor')));
const AddKeyAccount = Loadable(lazy(() => import('views/brand/AddKeyAccount')));
const ApplyKeyAccount = Loadable(lazy(() => import('views/brand/ApplyKeyAccount')));
const AddPointsOfSale = Loadable(lazy(() => import('views/brand/AddPointsOfSale')));
const AddCustomerLandscape = Loadable(lazy(() => import('views/brand/AddCustomerLandscape')));

const LollyAdvance = Loadable(lazy(() => import('views/lolly/LollyAdvance')));
const AllTokens = Loadable(lazy(() => import('views/lolly/AllTokens')));
const SendTransaction = Loadable(lazy(() => import('views/lolly/sendTransaction')));
const Transactions = Loadable(lazy(() => import('views/lolly/transactions')));
const SellAdvance = Loadable(lazy(() => import('views/lolly/advance/SellAdvance')));
const BuyAdvance = Loadable(lazy(() => import('views/lolly/advance/BuyAdvance')));

const LollyProperty = Loadable(lazy(() => import('views/lolly/LollyProperty')));
const AllProperties = Loadable(lazy(() => import('views/lolly/AllProperties')));
const BuyProperty = Loadable(lazy(() => import('views/lolly/property/BuyProperty')));
const SellProperty = Loadable(lazy(() => import('views/lolly/property/SellProperty')));
const SendProperty = Loadable(lazy(() => import('views/lolly/property/SendProperty')));

const MyMoneyOverview = Loadable(lazy(() => import('views/lolly/money/overview')));
const SendUsd = Loadable(lazy(() => import('views/lolly/sendUsd')));
const UsdTransactions = Loadable(lazy(() => import('views/lolly/usdTransactions')));
const Withdraw = Loadable(lazy(() => import('views/lolly/money/Withdraw')));
const SendToFriend = Loadable(lazy(() => import('views/lolly/money/SendToFriend')));
const FreeLP = Loadable(lazy(() => import('views/lolly/money/FreeLP')));

const Vip = Loadable(lazy(() => import('views/lolly/vip/Vip')));
const AllVip = Loadable(lazy(() => import('views/lolly/vip/AllVip')));
const SellVIP = Loadable(lazy(() => import('views/lolly/vip/SellVIP')));
const SendVIP = Loadable(lazy(() => import('views/lolly/vip/SendVIP')));
const VIPTransactions = Loadable(lazy(() => import('views/lolly/vip/Transactions')));

const Advantages = Loadable(lazy(() => import('views/lolly/unlock/Advantages')));
const AddBusiness = Loadable(lazy(() => import('views/lolly/business/AddBusiness')));
const AddBrand = Loadable(lazy(() => import('views/lolly/brand/AddBrand')));
const AddBrandAdmin = Loadable(lazy(() => import('views/lolly/brand/AddBrandAdmin')));
const RequestBrandAdmin = Loadable(lazy(() => import('views/lolly/brand/RequestBrandAdmin')));
const IncludeInBrandGroup = Loadable(lazy(() => import('views/lolly/brand/IncludeInBrandGroup')));
const TaskList = Loadable(lazy(() => import('views/lolly/brand/TaskList')));
const BrandGroup = Loadable(lazy(() => import('views/lolly/brand/BrandGroup')));
const Rewards = Loadable(lazy(() => import('views/lolly/unlock/Rewards')));
const AgencyCommission = Loadable(lazy(() => import('views/lolly/unlock/AgencyCommission')));
const BrandBusiness = Loadable(lazy(() => import('views/lolly/unlock/BrandBusiness')));
const Features = Loadable(lazy(() => import('views/lolly/unlock/Features')));
const Phone = Loadable(lazy(() => import('views/lolly/ValidationWizard/Phone')));
const EmailVerification = Loadable(lazy(() => import('views/lolly/process/emailVerif')));
const InvitationCode = Loadable(lazy(() => import('views/lolly/process/InvitationCode')));

// admin
const AdminAdvance = Loadable(lazy(() => import('views/admin/advance/Advance')));
const CreateAdvance = Loadable(lazy(() => import('views/admin/advance/create-advance')));
const AdminAdvanceTransactions = Loadable(lazy(() => import('views/admin/advance/transactions')));

const CreateAdvanceService = Loadable(lazy(() => import('views/admin/advance/create-service')));
const CreateIssuer = Loadable(lazy(() => import('views/admin/advance/create-issuer')));

const AdminProperty = Loadable(lazy(() => import('views/admin/property/Property')));
const CreateProperty = Loadable(lazy(() => import('views/admin/property/create-property')));

const AdminVIP = Loadable(lazy(() => import('views/admin/vip/Vip')));
const CreateVIP = Loadable(lazy(() => import('views/admin/vip/create-vip')));
const AdminVIPTransactions = Loadable(lazy(() => import('views/admin/vip/Transactions')));

const AllMoney = Loadable(lazy(() => import('views/admin/money/AllMoney')));
const AdminMoneyTransactions = Loadable(lazy(() => import('views/admin/money/transactions')));

const AllUsers = Loadable(lazy(() => import('views/admin/users/AllUsers')));
const IDpages = Loadable(lazy(() => import('views/admin/ID-pages')));

const Test = Loadable(lazy(() => import('views/lolly/test')));
const SendAdmin = Loadable(lazy(() => import('views/lolly/sendAdmin')));
const SendAdvTo = Loadable(lazy(() => import('views/lolly/sendAdvTo')));
const Example = Loadable(lazy(() => import('views/lolly/carousel/example')));
const Example2 = Loadable(lazy(() => import('views/lolly/carousel/example2')));
const UploadImage = Loadable(lazy(() => import('views/utilities/UploadImage')));
const Uploads = Loadable(lazy(() => import('views/utilities/Uploads')));
const RequestInvoice = Loadable(lazy(() => import('views/lolly/requestInvoice')));
const CreateService = Loadable(lazy(() => import('views/admin/CreateService')));
const DeliveredService = Loadable(lazy(() => import('views/admin/DeliveredServices')));
const ServicesOverview = Loadable(lazy(() => import('views/admin/ServicesOverview')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/advance/send',
      element: (
        <SendTransaction />
      )
    },
    {
      path: '/admin/advance/send-to',
      element: (
        <SendAdvTo />
      )
    },
    // {
    //     path: '/money/send',
    //     element: (
    //       <AdminGuard>
    //         <SendUsd />
    //       </AdminGuard>
    //     )
    // },
    {
      path: '/admin/money/send',
      element: (
        <AdminGuard>
          <SendUsd />
        </AdminGuard>
      )
    },
    {
      path: '/admin/advance/edit/:tokenId',
      element: (
        <AdminGuard>
          <CreateAdvance />
        </AdminGuard>
      )
    },
    {
      path: '/admin/advance/send',
      element: (
        <AdminGuard>
          <SendTransaction />
        </AdminGuard>
      )
    },
    {
      path: '/admin/advance/transactions',
      element: (
        <AdminGuard>
          <AdminAdvanceTransactions />
        </AdminGuard>
      )
    },
    {
      path: '/admin/money/transactions',
      element: (
        <AdminGuard>
          <AdminMoneyTransactions />
        </AdminGuard>
      )
    },
    // {
    //     path: '/admin/send',
    //     element: (
    //         <AdminGuard>
    //             <SendAdmin />
    //         </AdminGuard>
    //     )
    // },
    {
      path: '/admin/advance',
      element: (
        <AdminGuard>
          <AdminAdvance />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/advance',
      element: (
        <AdminGuard>
          <AdminAdvance />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/property',
      element: (
        <AdminGuard>
          <AdminProperty />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/vip',
      element: (
        <AdminGuard>
          <AdminVIP />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/money',
      element: (
        <AdminGuard>
          <AllMoney />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/users',
      element: (
        <AdminGuard>
          <AllUsers />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/issuer',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/brands',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/businesses',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/key-accounts',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/brand-services',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/business-services',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/advantages',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/admins',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/billing',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/documents',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/ids/chipbot',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/agents/ids',
      element: (
        <AdminGuard>
          <IDpages />
        </AdminGuard>
      )
    },
    {
      path: '/admin/property',
      element: (
        <AdminGuard>
          <AdminProperty />
        </AdminGuard>
      )
    },
    {
      path: '/image',
      element: (
        <AdminGuard>
          <UploadImage />
        </AdminGuard>
      )
    },
    {
      path: '/uploads',
      element: (
        <AdminGuard>
          <Uploads />
        </AdminGuard>
      )
    },
    {
      path: '/verification',
      element: <Verification />
    },
    {
      path: '/admin/advance/create',
      element: (
        <AdminGuard>
          <CreateAdvance />
        </AdminGuard>
      )
    },
    {
      path: '/admin/property/create',
      element: (
        <AdminGuard>
          <CreateProperty />
        </AdminGuard>
      )
    },
    {
      path: '/admin/property/edit/:tokenId',
      element: (
        <AdminGuard>
          <CreateProperty />
        </AdminGuard>
      )
    },
    {
      path: '/admin/vip',
      element: (
        <AdminGuard>
          <AdminVIP />
        </AdminGuard>
      )
    },
    {
      path: '/admin/vip/create',
      element: (
        <AdminGuard>
          <CreateVIP />
        </AdminGuard>
      )
    },
    {
      path: '/admin/vip/edit/:tokenId',
      element: (
        <AdminGuard>
          <CreateVIP />
        </AdminGuard>
      )
    },
    {
      path: '/admin/users',
      element: (
        <AdminGuard>
          <AllUsers />
        </AdminGuard>
      )
    },
    {
      path: '/vip/all',
      element: <AllVip />
    },
    {
      path: '/admin/advance/create-service',
      element: (
        <AdminGuard>
          <CreateAdvanceService />
        </AdminGuard>
      )
    },
    {
      path: '/admin/create-issuer',
      element: (
        <AdminGuard>
          <CreateIssuer />
        </AdminGuard>
      )
    },
    {
      path: '/admin/create-service',
      element: (
        <AdminGuard>
          <CreateService />
        </AdminGuard>
      )
    },
    {
      path: '/admin/delivered-service',
      element: (
        <AdminGuard>
          <DeliveredService />
        </AdminGuard>
      )
    },
    {
      path: '/admin/services-overview',
      element: (
        <AdminGuard>
          <ServicesOverview />
        </AdminGuard>
      )
    },
    {
      path: '/test',
      element: (
        <AdminGuard>
          <Test />
        </AdminGuard>
      )
    },
    {
      path: '/advance',
      element: <LollyAdvance />
    },
    {
      path: '/advance/all',
      element: <AllTokens />
    },
    {
      path: '/property',
      element: <LollyProperty />
    },
    {
      path: '/property/all',
      element: <AllProperties />
    },
    {
      path: '/newsfeed',
      element: <NewsFeed />
    },
    {
      path: '/newsfeed-test',
      element: <NewsFeedTest />
    },
    {
      path: '/admin/newsfeed',
      element: <AdminNewsFeed />
    },
    {
      path: '/terms',
      element: <Terms />
    },
    {
      path: '/brands/brands-and-markets/add-brand',
      element: <AddBrand />
    },
    {
      path: '/brands/brands-and-markets/add-brand-admin',
      element: <AddBrandAdmin />
    },
    {
      path: '/brands/brands-and-markets/request-admin',
      element: <RequestBrandAdmin />
    },
    {
      path: '/brands/brands-and-markets/brand-group',
      element: <BrandGroup />
    },
    {
      path: '/brands/brands-and-markets/task-list',
      element: <TaskList />
    },
    {
      path: '/brands/brands-and-markets/include-in-brand-group',
      element: <IncludeInBrandGroup />
    },
    {
      path: '/brands/data-insights',
      element: <DataInsights />
    },
    {
      path: '/brands/newsfeed',
      element: <BrandNewsfeed />
    },
    {
      path: '/activity-log',
      element: <ActivityLog />
    },
    {
      path: '/brands/audit-trail',
      element: <AuditTrail />
    },
    {
      path: '/brands/admin-billing/documents',
      element: <Documents />
    },
    {
      path: '/documents',
      element: <Documents />
    },
    {
      path: '/suggestions',
      element: <Suggestions />
    },
    {
      path: '/calendar',
      element: <Calendar />
    },
    {
      path: '/admin/brands/create',
      element: <CreateCampaign />
    },
    //Campaigns/services listing
    {
      path: '/brands/brands-and-markets/:brandId',
      element: <Campaigns />
    },
    {
      path: '/brands/campaigns/:brandId',
      element: <Campaigns />
    },
    {
      path: '/brands/key-accounts/add',
      element: <AddKeyAccount />
    },
    {
      path: '/brands/key-accounts/apply',
      element: <ApplyKeyAccount />
    },
    {
      path: '/brands/key-accounts/:brandId',
      element: <Campaigns />
    },
    {
      path: '/brands/points-of-sale/add',
      element: <AddPointsOfSale />
    },
    {
      path: '/brands/points-of-sale/:brandId',
      element: <Campaigns />
    },
    {
      path: '/brands/competitors/add',
      element: <AddCompetitor />
    },
    {
      path: '/brands/competitors/:brandId',
      element: <Campaigns />
    },
    {
      path: '/brands/customers/add',
      element: <AddCustomerLandscape />
    },
    {
      path: '/brands/customers/:brandId',
      element: <Campaigns />
    },
    {
      path: '/brands/trends/:brandId',
      element: <Campaigns />
    },
    {
      path: '/brands/market-data/:brandId',
      element: <Campaigns />
    },
    //Campaign/service page
    {
      path: '/brands/brands-and-markets/service/:serviceId',
      element: <Proposal />
    },
    {
      path: '/brands/campaigns/service/:serviceId',
      element: <Proposal />
    },
    {
      path: '/brands/points-of-sale/service/:serviceId',
      element: <Proposal />
    },
    {
      path: '/brands/key-accounts/service/:serviceId',
      element: <Proposal />
    },
    {
      path: '/brands/competitors/service/:serviceId',
      element: <Proposal />
    },
    {
      path: '/brands/customers/service/:serviceId',
      element: <Proposal />
    },
    {
      path: '/brands/trends/service/:serviceId',
      element: <Proposal />
    },
    {
      path: '/brands/market-data/service/:serviceId',
      element: <Proposal />
    },
    //confirm
    {
      path: '/brands/brands-and-markets/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/campaigns/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/points-of-sale/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/key-accounts/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/competitors/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/customers/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/trends/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/market-data/confirm/:serviceId',
      element: <CampaignOrderConfirmation />
    },
    {
      path: '/brands/campaign/confirm',
      element: <CampaignOrderConfirmation />
    },
    //thank you
    {
      path: '/brands/brands-and-markets/thankyou/',
      element: <ThankYouPage />
    },
    {
      path: '/brands/campaigns/thankyou/',
      element: <ThankYouPage />
    },
    {
      path: '/brands/key-accounts/thankyou/',
      element: <ThankYouPage />
    },
    {
      path: '/brands/competitors/thankyou/',
      element: <ThankYouPage />
    },
    {
      path: '/brands/customers/thankyou/',
      element: <ThankYouPage />
    },
    {
      path: '/brands/trends/thankyou/',
      element: <ThankYouPage />
    },
    {
      path: '/brands/market-data/thankyou/',
      element: <ThankYouPage />
    },
    //
    {
      path: '/brands/admin-billing/billing',
      element: <Billing />
    },
    {
      path: '/brands/admin-billing/admins',
      element: <Admins />
    },
    {
      path: '/brands/admin-billing/documents',
      element: <Admins />
    },
    //
    {
      path: '/advance/transactions',
      element: <Transactions />
    },
    {
      path: '/advance/transactions/:eth_token',
      element: <Transactions />
    },
    {
      path: '/money/transactions',
      element: <UsdTransactions />
    },
    {
      path: '/money/transactions/:eth_token',
      element: <UsdTransactions />
    },
    {
      path: '/vip/transactions',
      element: <VIPTransactions />
    },
    {
      path: '/vip/transactions/:eth_token',
      element: <VIPTransactions />
    },
    {
      path: '/admin/vip/transactions',
      element: <AdminVIPTransactions />
    },
    {
      path: '/money',
      element: <MyMoneyOverview />
    },
    {
      path: '/admin/money/all',
      element: (
        <AdminGuard>
          <AllMoney />
        </AdminGuard>
      )
    },
    {
      path: '/money/withdraw',
      element: <Withdraw />
    },
    {
      path: '/send-to-friend',
      element: <SendToFriend />
    },
    {
      path: '/free-lp',
      element: <FreeLP />
    },
    {
      path: '/advantages',
      element: <Advantages />
    },
    {
      path: '/business/add-business',
      element: <AddBusiness />
    },
    {
      path: '/property/buy',
      element: <BuyProperty />
    },
    {
      path: '/property/sell',
      element: <SellProperty />
    },
    {
      path: '/admin/property/send',
      element: (
        <AdminGuard>
          <SendProperty />
        </AdminGuard>
      )
    },
    {
      path: '/advance/buy',
      element: <BuyAdvance />
    },
    {
      path: '/advance/sell',
      element: <SellAdvance />
    },
    {
      path: '/vip/sell',
      element: <SellVIP />
    },
    {
      path: '/admin/vip/send',
      element: (
        <AdminGuard>
          <SendVIP />
        </AdminGuard>
      )
    },
    {
      path: '/agency',
      element: <AgencyCommission />
    },
    {
      path: '/rewards',
      element: <Rewards />
    },
    {
      path: '/your-brand',
      element: <BrandBusiness />
    },
    {
      path: '/features',
      element: <Features />
    },
    {
      path: '/vip',
      element: <Vip />
    },
    {
      path: '/phone',
      element: <Phone />
    },
    {
      path: '/email-verification',
      element: <EmailVerification />
    },
    {
      path: '/invitation-code',
      element: <InvitationCode />
    },
    {
      path: '/example',
      element: (
        <AdminGuard>
          <Example2 />
        </AdminGuard>
      )
    },
    {
      path: '/request-invoice',
      element: <RequestInvoice />
    }
  ]
};

export default MainRoutes;
