import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';
import { US } from 'country-flag-icons/react/3x2'

const icons = { US };

const brands = {
  id: 'my-brands',
  title: <FormattedMessage id="My brands" />,
  type: 'group',
  admin: true,
  children: [{
    id: 'campaigns-brand',
    title: 'Campaigns',
    type: 'item',
    url: '/campaigns',
    icon: icons.US,
    children: [],
  }]
};

export default brands;