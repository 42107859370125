// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconUserCircle, IconBellRinging, IconSettings, IconSitemap, IconZoomCheck, IconSend, IconLockOpen, IconCurrencyDollar, IconWallet, IconThumbUp, IconBulb, IconTrademark, IconAlphabetGreek, IconVip, IconHomeDollar, IconNews } from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconUserCircle,
  IconBellRinging,
  IconSettings,
  IconSitemap,
  IconZoomCheck,
  IconSend,
  IconLockOpen,
  IconCurrencyDollar,
  IconWallet,
  IconThumbUp,
  IconBulb,
  IconTrademark,
  IconAlphabetGreek,
  IconVip,
  IconHomeDollar,
  IconNews
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: <FormattedMessage id="My personal account" />,
  type: 'group',
  children: [
    {
      id: 'newsfeed',
      title: <FormattedMessage id="My Newsfeed" />,
      type: 'item',
      url: '/newsfeed',
      icon: icons.IconBellRinging,
    },
    {
      id: 'unlock',
      title: <FormattedMessage id="Unlock" />,
      type: 'collapse',
      icon: icons.IconLockOpen,
      children: [
        {
          id: 'phone',
          title: <FormattedMessage id="Phone Verification" />,
          type: 'item',
          url: '/phone',
        },
        {
          id: 'verification',
          title: <FormattedMessage id="Account Verification" />,
          type: 'item',
          url: '/verification',
        },
        {
          id: 'invitation-code',
          title: <FormattedMessage id="Invitation codes" />,
          type: 'item',
          url: '/invitation-code',
        },
        {
          id: 'send-to-friend',
          title: <FormattedMessage id="$20 for friends" />,
          type: 'item',
          url: '/send-to-friend',
          // disabled: true,
        },
        {
          id: 'free-lp',
          title: <FormattedMessage id="Free Property" />,
          type: 'item',
          url: '/free-lp',
          // disabled: true,
        },
        {
          id: 'rewards',
          title: <FormattedMessage id="Earn Advance" />,
          type: 'item',
          url: '/rewards',
          // disabled: true,
        },
      ],
    },
    {
      id: 'money',
      title: <FormattedMessage id="My Money" />,
      type: 'collapse',
      icon: icons.IconCurrencyDollar,
      breadcrumbs: true,
      children: [
        {
          id: 'money-overview',
          title: <FormattedMessage id="Overview" />,
          type: 'item',
          url: '/money'
        },
        {
          id: 'usd-transactions',
          title: <FormattedMessage id="Transactions" />,
          type: 'item',
          url: '/money/transactions',
          // disabled: true,
        },
        {
          id: 'send-usd',
          title: <FormattedMessage id="Send" />,
          type: 'item',
          url: '/money/send',
          disabled: true,
        },
        {
          id: 'withdraw-usd',
          title: <FormattedMessage id="Withdraw" />,
          type: 'item',
          url: '/money/withdraw',
          // disabled: true
        },
      ]
    },
    {
      id: 'lollyadvance',
      title: <FormattedMessage id="My Advance" />,
      type: 'collapse',
      icon: icons.IconWallet,
      breadcrumbs: true,
      children: [
        {
          id: 'advance-overview',
          title: <FormattedMessage id="Overview" />,
          type: 'item',
          url: '/advance',
          breadcrumbs: true
        },
        {
          id: 'la-transactions',
          title: <FormattedMessage id="Transactions" />,
          type: 'item',
          url: '/advance/transactions',
          breadcrumbs: true,
        },
        {
          id: 'la-send',
          title: <FormattedMessage id="Send" />,
          type: 'item',
          url: '/advance/send',
          breadcrumbs: true,
        },
        {
          id: 'la-buy',
          title: <FormattedMessage id="Buy" />,
          type: 'item',
          url: '#',
          breadcrumbs: true,
          disabled: true
        },
        {
          id: 'la-sell',
          title: <FormattedMessage id="Sell" />,
          type: 'item',
          url: '/advance/sell',
          breadcrumbs: true,
          // disabled: true
        },
        // {
        //   id: 'alltokens',
        //   title: <FormattedMessage id="What's available?" />,
        //   type: 'item',
        //   url: '/advance/all',
        //   breadcrumbs: true
        // },
      ]
    },
    {
      id: 'lollyproperty',
      title: <FormattedMessage id="My Property" />,
      type: 'collapse',
      icon: icons.IconHomeDollar,
      breadcrumbs: true,
      children: [
        {
          id: 'property-overview',
          title: <FormattedMessage id="Overview" />,
          type: 'item',
          url: '/property',
          breadcrumbs: true
        },
        {
          id: 'property-transactions',
          title: <FormattedMessage id="Transactions" />,
          type: 'item',
          url: '#',
          breadcrumbs: true,
          disabled: true
        },
        {
          id: 'send-property',
          title: <FormattedMessage id="Send" />,
          type: 'item',
          url: '#',
          breadcrumbs: true,
          disabled: true
        },
        {
          id: 'buy-property',
          title: <FormattedMessage id="Buy" />,
          type: 'item',
          url: '/property/buy',
          breadcrumbs: true,
          // disabled: true
        },
        {
          id: 'sell-property',
          title: <FormattedMessage id="Sell" />,
          type: 'item',
          url: '/property/sell',
          breadcrumbs: true,
          // disabled: true
        },
        // {
        //   id: 'all-property',
        //   title: <FormattedMessage id="What's available?" />,
        //   type: 'item',
        //   url: '/property/all',
        //   breadcrumbs: true,
        //   // disabled: true
        // },
      ]
    },
    {
      id: 'lolly-vip',
      title: <FormattedMessage id="My VIP" />,
      type: 'collapse',
      icon: icons.IconVip,
      breadcrumbs: true,
      children: [
        {
          id: 'vip-overview',
          title: <FormattedMessage id="Overview" />,
          type: 'item',
          url: '/vip',
          breadcrumbs: true,
          // disabled: true
        },
        {
          id: 'vip-transactions',
          title: <FormattedMessage id="Transactions" />,
          type: 'item',
          url: '/vip/transactions',
          breadcrumbs: true,
          // disabled: true
        },
        {
          id: 'vip-send',
          title: <FormattedMessage id="Send" />,
          type: 'item',
          url: '#',
          breadcrumbs: true,
          disabled: true
        },
        {
          id: 'vip-buy',
          title: <FormattedMessage id="Buy" />,
          type: 'item',
          url: '#',
          breadcrumbs: true,
          disabled: true
        },
        {
          id: 'vip-sell',
          title: <FormattedMessage id="Sell" />,
          type: 'item',
          url: '/vip/sell',
          breadcrumbs: true,
          // disabled: true
        },
        {
          id: 'all-vip',
          title: <FormattedMessage id="What's available?" />,
          type: 'item',
          url: '/vip/all',
          breadcrumbs: true,
          // disabled: true
        },
      ]
    },
    {
      id: 'settings',
      title: <FormattedMessage id="Settings" />,
      type: 'collapse',
      icon: icons.IconSettings,
      breadcrumbs: true,
      children: [
        {
          id: 'my-profile',
          title: <FormattedMessage id="My Profile" />,
          type: 'item',
          url: '#',
          breadcrumbs: true,
          disabled: true
        },
        {
          id: 'home-market',
          title: <FormattedMessage id="Home Market" />,
          type: 'item',
          url: '/home-market',
        },
        {
          id: 'my-documents',
          title: <FormattedMessage id="My Documents" />,
          type: 'item',
          url: '/documents',
          breadcrumbs: true,
          disabled: true
        }
      ]
    },
    {
      id: 'admin-billing',
      title: <FormattedMessage id="Admin & Billing" />,
      type: 'collapse',
      icon: icons.IconSettings,
      breadcrumbs: true,
      children: [
        {
          id: 'request-invoice',
          title: <FormattedMessage id="My Invoices" />,
          type: 'item',
          url: '/request-invoice',
          breadcrumbs: true,
        },
      ]
    },
    {
      id: 'activity-log',
      title: <FormattedMessage id="My activity log" />,
      type: 'item',
      url: '/activity-log',
      icon: icons.IconNews,
      disabled: true
    },
  ]
};

export default dashboard;
