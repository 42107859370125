// useNavigationEvent.js

import { useEffect, useState } from 'react';

const useNavigationEvent = () => {
  const [trackedPages, setTrackedPages] = useState([]);
  const [isNavigating, setIsNavigating] = useState(false);

  const trackPage = (path) => {
    setTrackedPages((prevTrackedPages) => [...prevTrackedPages, path]);
  };

  const triggerNavigationEvent = () => {
    setIsNavigating(true);
    // Additional logic for navigation event (e.g., showing an alert)
    chipBotApi.close();
    alert('Leaving a tracked page');
  };

  useEffect(() => {
    if (isNavigating) {
      // Additional logic to execute when navigation event occurs
      setIsNavigating(false);
    }
  }, [isNavigating]);

  return {
    trackPage,
    triggerNavigationEvent,
  };
};

export default useNavigationEvent;
