import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const BrandRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/BrandRegister')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const legostarwarsRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/legostarwars-register')));

// ==============================|| AUTH ROUTING ||============================== //
const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/signin',
            element: <AuthLogin loginProp={1}/>
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/live-register',
            element: <AuthRegister />
        },
        {
            path: '/property-register',
            element: <AuthRegister />
        },
        {
            path: '/vip-register',
            element: <AuthRegister />
        },
        {
            path: '/advance-register',
            element: <AuthRegister />
        },
        {
            path: '/brand-register',
            element: <BrandRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/legostarwars-register',
            element: <legostarwarsRegister />
        },
        {
            path: '/login',
            element: <Navigate  to="/signin" />
        }
    ]
};

export default LoginRoutes;
