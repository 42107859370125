import { memo, useEffect, useState } from 'react';

// material-ui
import { Typography } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import {
  IconDeviceAnalytics,
  IconUsers,
  IconSpeakerphone,
  IconTournament,
  IconTrendingUp,
  IconChartArrowsVertical,
  IconAward,
  IconHierarchy,
  IconBellRinging,
  IconNews,
  IconRoute,
  IconTrademark,
  IconSettings,
  IconMapPins,
  IconLockOpen
} from '@tabler/icons';

// project imports
import NavGroup from './NavGroup';
import initialMenuItems from 'menu-items';
import useAuth from 'hooks/useAuth';

const icons = {
  IconDeviceAnalytics,
  IconUsers,
  IconSpeakerphone,
  IconTournament,
  IconTrendingUp,
  IconChartArrowsVertical,
  IconAward,
  IconHierarchy,
  IconBellRinging,
  IconNews,
  IconRoute,
  IconTrademark,
  IconSettings,
  IconMapPins,
  IconLockOpen
}

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { getToken, user } = useAuth();
  const [menu, setMenu] = useState(initialMenuItems); // use initialMenuItems as initial state

  async function myBrandsEmptyMenu() {
    const newMenu = {
      id: 'my-brands',
      title: <FormattedMessage id='My brands' />,
      // titleId: 'My brands',
      type: 'group',
      admin: false,
      children: [
        {
          id: 'brands-and-markets',
          title: <FormattedMessage id="Brands and markets" />,
          type: 'collapse',
          icon: icons.IconAward,
          children: [
            {
              id: 'add-brand',
              title: 'Add a brand',
              type: 'item',
              url: '/brands/brands-and-markets/add-brand',
              breadcrumbs: true,
              // disabled: true
            },
          ]
        }
      ]
    }
    return newMenu;
  }

  async function organizeNewMenu(children) {
    const newMenu = {
      id: 'my-brands',
      title: <FormattedMessage id='My brands' />,
      // titleId: 'My brands',
      type: 'group',
      admin: false,
      children: [
        {
          id: 'brand-newsfeed',
          title: <FormattedMessage id="My brand newsfeed" />,
          type: 'item',
          url: '/brands/newsfeed',
          icon: icons.IconBellRinging,
          // disabled: true
        },
        {
          id: 'brands-unlock',
          title: <FormattedMessage id="Unlock" />,
          type: 'collapse',
          icon: icons.IconLockOpen,
          children: [
            {
              id: 'brands-verification',
              title: <FormattedMessage id="Account Verification" />,
              type: 'item',
              url: '/verification',
            },
            {
              id: 'brands-invitation-code',
              title: <FormattedMessage id="Invitation codes" />,
              type: 'item',
              url: '/invitation-code',
            },
          ]
        },
        {
          id: 'brand-audit-trail',
          title: <FormattedMessage id="Brand audit trail" />,
          type: 'item',
          url: '/brands/audit-trail',
          icon: icons.IconRoute,
          // disabled: true
        },
        {
          id: 'data-insights',
          title: <FormattedMessage id="Data insights" />,
          type: 'item',
          url: '/brands/data-insights',
          icon: icons.IconDeviceAnalytics,
        },
      ]
    };
    // add icon to the first child, called icon.IconUsers
    // console.log(children)
    // if(children[1].icon === undefined) {
    children[0].icon = icons.IconAward;
    children[1].icon = icons.IconSpeakerphone;
    children[2].icon = icons.IconMapPins;
    children[3].icon = icons.IconHierarchy;
    children[4].icon = icons.IconTournament;
    children[5].icon = icons.IconUsers;
    children[6].icon = icons.IconTrendingUp;
    children[7].icon = icons.IconChartArrowsVertical;
    newMenu.children.push(...children);
    const moreChildren = [
      {
        id: 'admin-billing',
        title: <FormattedMessage id="Admin & Billing" />,
        type: 'collapse',
        icon: icons.IconSettings,
        children: [
          {
            id: 'billing',
            title: <FormattedMessage id="Billing" />,
            type: 'item',
            url: '/brands/admin-billing/billing',
          },
          {
            id: 'admins',
            title: <FormattedMessage id="Admins" />,
            type: 'item',
            url: '/brands/admin-billing/admins',
          },
          {
            id: 'admin-documents',
            title: <FormattedMessage id="Documents" />,
            type: 'item',
            url: '/brands/admin-billing/documents',
          },
        ]
      },
    ]
    newMenu.children.push(...moreChildren);
    // console.log(newMenu)
    return newMenu;
  }

  async function getUserBrands() {
    // for a regular user changing path to check token balances
    let url = `${process.env.REACT_APP_URL}/get-user-brands`;

    const token = await getToken();

    const headers = new Headers();

    // headers.append('Content-Type', 'text/json');
    headers.append('Authorization', `Bearer ${token}`);

    const result = await fetch(url, {
      method: 'GET',
      headers
      // credentials: 'user:passwd'
    })
      .then((res) => res.json())
      .then(
        async (result) => {
          // if result is not empty array
          const alreadyExists = menu.items.some(item => item.id === 'my-brands');

          if (result.length === 0 && !alreadyExists) {
            const newMenu = [...menu.items]; // create a copy of existing items
            const brandsMenu = await myBrandsEmptyMenu();
            newMenu.splice(1, 0, brandsMenu); // insert result at the third position (index 2)
            setMenu({ ...menu, items: newMenu });
            return menu;
          }
          // console.log(result);
          // check if the new items are already in the menu items
          if (!alreadyExists) {
            const newMenu = [...menu.items]; // create a copy of existing items
            const brandsMenu = await organizeNewMenu(result);
            newMenu.splice(1, 0, brandsMenu); // insert result at the third position (index 2)
            setMenu({ ...menu, items: newMenu }); // update state with new menu
            // console.log(newMenu);
          }
          return menu; // Added return statement
          // success();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          return error;

        }
      );
  };


  useEffect(() => {
    if (user) {
      getUserBrands().catch(error => {
        // handle error for example by setting some error state
        console.error('There was an error fetching user brands:', error);
      });
    }
  }, []); // only run on component mount

  const navItems = menu?.items?.map((item) => {
    let title;
    if (item.titleId) {
      // If this is the unique item that needs FormattedMessage
      title = <FormattedMessage id={item.titleId} />;
    } else {
      title = item.title;
    }

    switch (item.type) {
      case 'group':
        return <NavGroup
          key={item.id}
          item={{ ...item, title: title }}
        />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
