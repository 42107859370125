import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// routing
const AdvanceToken = Loadable(lazy(() => import('views/lolly/token-pages/AdvanceToken')));
const PropertyToken = Loadable(lazy(() => import('views/lolly/property-pages/PropertyToken')));
const VipToken = Loadable(lazy(() => import('views/lolly/vip/VipToken')));
const MoneyToken = Loadable(lazy(() => import('views/lolly/money/MoneyToken')));
const ResendEmailVerificaion = Loadable(lazy(() => import('views/lolly/process/ResendEmailVerificaion')));
const EmailVerified = Loadable(lazy(() => import('views/lolly/process/EmailVerified')));
const FreeTokens = Loadable(lazy(() => import('views/lolly/process/FreeTokens')));
const HomeMarket = Loadable(lazy(() => import('views/lolly/ValidationWizard/HomeMarket')));
const VerifiedQR = Loadable(lazy(() => import('views/lolly/vip/VerifiedQR')));

// ==============================|| AUTH ROUTING ||============================== //

const GuestRoutes = {
  path: '/',
  element: (
    <MainLayout />
  ),
  children: [
    {
      path: '/advance/:eth_token',
      element: <AdvanceToken />
    },
    {
      path: '/money/:eth_token',
      element: <MoneyToken />
    },
    {
      path: '/property/:eth_token',
      element: <PropertyToken />
    },
    {
      path: '/vip/verified',
      element: <VerifiedQR />
    },
    {
      path: '/vip/:eth_token',
      element: <VipToken />
    },
    {
      path: '/resend-email-verification',
      element: <ResendEmailVerificaion />
    },
    {
      path: '/email-verified',
      element: <EmailVerified />
    },
    {
      path: '/home-market',
      element: <HomeMarket />
    },
    {
      path: '/free-tokens',
      element: <FreeTokens />
    },
  ]
};

export default GuestRoutes;
