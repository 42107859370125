import { lazy } from 'react';
// NewLayoutRoutes.js
import NewLayout from 'layout/MainLayout/NewLayout.js';
import Loadable from 'ui-component/Loadable';

const NewDashboard = Loadable(lazy(() => import('views/new/NewDashboard')));
const NewsFeedTest = Loadable(lazy(() => import('views/lolly/NewsFeedTest')));
const NewsFeed = Loadable(lazy(() => import('views/lolly/NewsFeed')));
const NewProposal = Loadable(lazy(() => import('views/brand/calendar/NewProposal')));
// import NewProfile from './path-to-newprofile';

const NewLayoutRoutes = {
  path: '/new',  // Base path for the new layout
  element: (
    <NewLayout />
  ),
  children: [
    {
      path: 'newsfeed',
      element: <NewsFeed />  // New components specific to NewLayout
    },
    {
      path: 'dashboard',
      element: <NewsFeedTest />  // New components specific to NewLayout
    },
    {
      path: 'proposal',
      element: <NewProposal />
    }
    // {
    //   path: 'profile',
    //   element: <NewProfile />
    // },
  ]
};

export default NewLayoutRoutes;
