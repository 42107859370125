import { useEffect } from "react"

const useCrisp = () => {
  useEffect(() => {
    // if (process.env.NODE_ENV !== 'development') {
        window.CHIPBOT_ID = 'act_pp1fs0TqC-sm';window[['CHIPBOT','DOMAIN','ID'].join('_')] = 'dom_rTT1tpSslMbg';!function(c,h,i,p,b,o,t){o=h.createElement(i),t=h.getElementsByTagName(i)[0],o.async=1,o.src="https://static.getchipbot.com/edge/p/chipbot.js",t.parentNode.insertBefore(o,t)}(window,document,"script");
    // }
  });
}

export default useCrisp;