import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import { openDrawerRight } from 'store/slices/menu'; // Make sure to create this slice
import MenuList from './Sidebar/MenuList';

const RightSidebar = ({ window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const { drawerOpenRight } = useSelector((state) => state.menu);

  const drawer = useMemo(
    () => (
      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        {/* Add your menu or content here */}
        <MenuList />
      </PerfectScrollbar>
    ),
    [matchUpMd]
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="right"
        open={drawerOpenRight}
        onClose={() => dispatch(openDrawerRight(!drawerOpenRight))}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderLeft: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerOpenRight && drawer}
      </Drawer>
    </Box>
  );
};

RightSidebar.propTypes = {
  window: PropTypes.object
};

export default memo(RightSidebar);
